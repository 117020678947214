<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-start>
      <v-flex xs10 text-left >
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >Payment Details</span
        >
      </v-flex>
      
      <v-flex xs10 v-for="(item, i) in paymentData" :key="i" text-left pt-3>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <span
              style="font-family: poppinsbold; font-size: 18px; color: #53a874"
            >
              Ticket #{{ item.payment.ticket.ticketNo }}
            </span>
          </v-flex>
          <v-flex xs12 md10 v-for="(item2, index) in item.booking" :key="index">
            <BookingHistory
              v-bind:storage="item2"
              v-bind:payment="item.payment"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex xs12 md10>
            <v-card outlined>
              <v-layout
                wrap
                justify-start
                pa-2
                v-if="item.guestsEntranceTickets.length > 0"
              >
                <v-flex xs12 pb-2>
                  <span class="textField2"> Entrance Ticket Details </span>
                </v-flex>
                <v-flex
                  xs12
                  lg11
                  v-for="(guest, i) in item.guestsEntranceTickets"
                  :key="i"
                >
                  <v-layout wrap justify-start class="pageForm">
                    <v-flex xs4 text-left>
                      {{ formatDate(guest.bookingDate) }}
                    </v-flex>
                    <v-flex xs4 text-left>
                      {{ guest.guest.name }}
                      <span
                        v-if="guest.status == 'Cancelled'"
                        style="color: #ff4444; font-size: 12px"
                      >
                        (Cancelled)
                      </span>
                    </v-flex>
                    <v-flex xs4 text-right> {{ guest.charge }} INR </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start pa-2 v-if="item.vehicles.length > 0">
                <v-flex xs12 pb-2>
                  <span class="textField2"> Vehicle Details</span>
                </v-flex>
                <v-flex
                  xs12
                  lg11
                  v-for="(vehicle, i) in item.vehicles"
                  :key="i"
                >
                  <v-layout wrap justify-start class="pageForm">
                    <v-flex xs4 text-left>
                      {{ formatDate(vehicle.bookingDate) }}
                    </v-flex>
                    <v-flex xs4 text-left>
                      {{ vehicle.vehicle }}
                      <span
                        v-if="vehicle.status == 'Cancelled'"
                        style="color: #ff4444; font-size: 12px"
                      >
                        (Cancelled)
                      </span>
                    </v-flex>
                    <v-flex xs4 text-right> {{ vehicle.charge }} INR </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start pa-2>
                <v-flex xs12 lg11>
                  <v-layout wrap justify-start class="textField2">
                    <v-flex xs8 text-left> Payment Gateway Charge </v-flex>
                    <v-flex xs4 text-right>
                      {{
                        item.payment.total -
                        (item.programmeTotal +
                          item.guestsEntranceTicketCharge +
                          item.vehicleCharge)
                      }}
                      INR
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 md10>
            <v-layout wrap py-2>
              <v-flex xs12>
                <v-card outlined>
                  <v-layout wrap justify-start pa-2>
                    <v-flex xs12 lg11>
                      <v-layout wrap justify-space-between>
                        <v-flex xs6 text-left>
                          <span class="textField2" style="color: #000000">
                            Grand Total
                          </span>
                        </v-flex>
                        <v-flex xs4 text-right>
                          <span class="textField2" style="color: #000000">
                            {{ item.payment.total }} INR
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md10 py-2>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import BookingHistory from "./bookingHistory";
export default {
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      paymentData: [],
    };
  },
  components: {
    BookingHistory,
  },
  beforeMount() {
    this.getData();

    // if (this.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    // this.getProgramme();
    // this.getExcel();
    // if (this.date) {
    //   this.date.setDate(this.date.getDate() + 1);
    //   this.date = this.date.toISOString().substr(0, 10);
    // }
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/adminreport",
        headers: {
          token: localStorage.getItem("token"),
        },
        params:{ticket:this.$route.query.id}
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.paymentData = response.data.data
            // this.totalAmount = response.data.validtotal;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // getExcel() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/booking/admingetlistexcel",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       date: this.date,
    //       programme: this.programme,
    //       slotDetail: this.slotDetail,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         for (var i = 0; i < response.data.data.length; i++) {
    //           response.data.data[i].bookingDate = this.formatDate(
    //             response.data.data[i].bookingDate
    //           );
    //           response.data.data[i].guest.dob = this.formatDate(
    //             response.data.data[i].guest.dob
    //           );
    //         }
    //         this.excel = response.data.data;
    //         this.json_data = this.excel;
    //         this.appLoading = false;
    //       } else {
    //         this.msg = response.data.msg;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    // getProgramme() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/programme/getlist",
    //     method: "GET",
    //     params: { programmestatus: "started" },
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.appLoading = false;
    //         var temp = [
    //           {
    //             name: null,
    //             value: null,
    //           },
    //         ];
    //         temp = response.data.data;
    //         for (var i = 0; i < temp.length; i++) {
    //           temp[i].name = temp[i].progName;
    //           temp[i].value = temp[i]._id;
    //         }
    //         this.programs = temp;
    //         this.programs.unshift({ name: "All Programs", value: null });
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    // getSlot() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/programme/slots/getlist",
    //     method: "GET",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       programme: this.programme,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.appLoading = false;
    //         this.slotItems = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.head {
  font-family: poppinsregular;
  font-size: 14px;
}
.content {
  font-family: poppinsbold;
  font-size: 13px;
}
</style>